import React from 'react'
import { Helmet } from 'react-helmet'
import Header from '../components/header'
import Footer from '../components/footer'
import { Link } from 'gatsby'
import Img1 from '../images/hero-windows.png'
import video1 from '../images/Windows-Wizard-2.mp4'
import video2 from '../images/Windows-Constructor-2.mp4'
import video3 from '../images/Windows-Prod-2.mp4'

export default function Windows(props) {
	return <>
		<Helmet>
			<title>App for Windows industry: configurator, manufacturing | Kelgrand</title>
		</Helmet>

		<div style={{
			backgroundColor: '#F9FBE6',
			paddingBottom: '3rem',
		}}>

			<Header path={props.path} />

			<main className='container mt-5'>
				<div className='row'>
					<div className='col-sm-4'>
						<h1 className='display-3 green-heading'>
							All-in-one Toolkit for Window industry
						</h1>
						<h2 className='green-heading mt-5'>
							PVC, Alu, Wood
						</h2>
						<p>
							Resellers and Manufacturers.<br />
							Quotes, BOM, Works Order …
						</p>
					</div>
					<div className='col-sm-7 offset-sm-1'>
						<img src={Img1} style={{width:'100%'}} />
					</div>
				</div>
			</main>
		</div>

		<div className='container'>
			<div className='row mt-5 mb-5'>
				<div className='col-sm-4'>
					<h2 className='green-heading mt-5'>
						Increase productivity by up to 41 %
					</h2>
					<h3 className='green-heading mt-4'>
						Create quotes with a wizard
					</h3>
					<p>
						Creating a quote or work order is now much faster. 
					</p>
				</div>
				<div className='col-sm-6 offset-sm-2'>
					<video src={video1} autoPlay={true} loop={true} muted={true} playsInline={true} controlsList="nodownload" style={{width: '100%'}} />
				</div>
			</div>
			
			<hr />

			<div className='row mt-5 mb-5'>
				<div className='col-sm-4'>
					<h2 className='green-heading mt-5'>
						Powerful construction
					</h2>
					<h3 className='green-heading mt-4'>
						Easy drag-drop
					</h3>
					<p>
						Build complex windows with simple drag and drop editor. Every part of window is selectable with a mouse and you can change price, color, dimensions, …
					</p>
					<p>
						Combine multiple windows or glass units. Add mullions, muntins, grilles …
					</p>
					<p>
						Easy to learn.
					</p>
				</div>
				<div className='col-sm-6 offset-sm-2'>
					<video src={video2} autoPlay={true} loop={true} muted={true} playsInline={true} controlsList="nodownload" style={{width: '100%'}} />
				</div>
			</div>

			<hr />

			<div className='row mt-5 mb-5'>
				<div className='col-sm-4'>
					<h2 className='green-heading mt-5'>
						Agile Manufacturing
					</h2>
					<h3 className='green-heading mt-4'>
						Connect Machines, Export Data
					</h3>
					<p>
						Prepare custom reports for Work Order, Bill Of Materials, fittings used, cutting stock optimisation, print labels …
					</p>
					<p>
						Export data to your CNC machine via CSV file or other file formats.
					</p>
				</div>
				<div className='col-sm-6 offset-sm-2'>
					<video src={video3} autoPlay={true} loop={true} muted={true} playsInline={true} controlsList="nodownload" style={{width: '100%'}} />
				</div>
			</div>

			<hr />

			<div className='row mt-5 mb-5'>
				<div className='col-sm-6'>
					<h2 className='green-heading'>
						Features
					</h2>
					<h3 className='green-heading'>
						Shades, Blinds, Alu Cladding
					</h3>
					<p>
						Include additional items with a simple mouse click. App will calculate price and dimensions.
					</p>

					<h3 className='green-heading'>
						Barcode &amp; CNC
					</h3>
					<p>
						Use tablet computer on the shop floor to manage machines, get project information, cutting profiles, installing fittings, packaging windows …
					</p>
					<h3 className='green-heading'>
						Tablet computer on the shop floor
					</h3>
					<p>
						Use tablet computer on the shop floor to manage machines, get project information, cutting profiles, installing fittings, packaging windows …
					</p>
					<h3 className='green-heading'>
						Online Configurator for your customers
					</h3>
					<p>
						Your customers can access configurator on your website and enter data into the system. You can review their requests, assign discount, charge additional services and send then them your quote.
					</p>
					<h3 className='green-heading'>
						Fittings
					</h3>
					<p>
						Our solution will calculate exact fitting parts you need for a window.
					</p>
					<h3 className='green-heading'>
						Extensions, Connectors, Mullions
					</h3>
					<h3 className='green-heading'>
						Optimize cutting stock
					</h3>
					<h3 className='green-heading'>
						Sample database that you can customize
					</h3>
				</div>
			</div>
		</div>

		<Footer />
	</>
}
